export class coveragesPnfResponse{
    constructor(
        public coveragesPnf: coveragesPnfdata
    ) { }
}
export class coveragesPnfdata
{
    policyAggregate: number;
    aggregatePerInsuredEvent: number;
    selfInsuredRetention: number;
    insuredLosses: insuredLosses;
    insuredEvents: insuredEvents;
    triaIncluded: null;
    riskId: string;
    submissionId: string;
    quoteNumber: string;
    quoteId: string;
    insuredName: string;
    broker: string;
    lob: string;
    lobId: string;
    transactionType: string;
    status: string;
    stateCode: string;
    stateName: string;
    countryCode: string;
    termLength: string;
    effectiveDate: string;
    expirationDate: string;
    quoteShare: boolean;
    hccSharePercentage: number;
    filingResponsibility: string;
    taxState: string;
    taxesAndFees: taxAndFeeData[];
    basePremium: number;
    triaPremium: number;
    taxesAndFeesPremium: number;
    totalPremium: number;
    termLengths: string[];
    filingResponsibilities: data[];
    surplusLineTaxes: data[];
    primaryCarrier: dataCompany[];
    indemnityPeriods: data[];
    excessPolicyTypes: string;
    excessCoverageTypes: string;    
}
export class data{    
    id: number;
    description: string;    
}
export class dataCompany{    
    id: number;
    companyName: string;    
}
export class taxAndFeeData{    
    description: string;
    amount: number;   
}
export class insuredLosses{
    recallExpenses: number;
    customerRecallExpenses: number;
    preRecallContaminationExpenses: number;
    replacementCosts: number;
    rehabilitationExpenses: number;
    increasedCostOfWorking: number;
    defenseCosts: number;
    crisisConsultantExpenses: number;
    triaRate: number;    
}
export class insuredEvents{
    retroactiveDate: string;
    indemnityPeriod: string;
    adversePublicity: number;
    productDefect: number;    
}